import '../../static/css/reset.css'
import '../../static/css/wrapper.css'
import '../../static/css/header.css'
import '../../static/css/profile.css'
import '../../static/css/breadcrumb.css'
import '../../static/css/font_awesome.css'
import '../../static/css/footer.css'
import React from 'react'
import { Header } from '../components/Header'
import { Breadcrumb } from '../components/Breadcrumb'
import { Footer } from '../components/Footer'
import Seo from '../components/Seo'
import { url } from '../config/domain'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export default function Profile({ location }: any): JSX.Element {
  const locatePath = location.pathname.split('/')

  // 参考 : https://qiita.com/Masanori_N/items/839a9b5a97decd564c6f
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    arrows: false,
    fade: true,
  }

  return (
    <div>
      <div className="wrapper">
        <Seo
          title={'黒光俊秀のプロフィール詳細'}
          description={
            'KURORO BLOGを運営する筆者の、黒光俊秀のプロフィール詳細ページです。KURORO BLOGは、「モノづくりから始まるエンジニア」をコンセプトに、プログラミングに関心を持ってもらうための情報共有サイトです。'
          }
          url={url + 'profile/'}
          noIndex={false}
          contentType="article"
          isProfilePage={true}
        />

        <Header location={locatePath} />

        <div className="profile">
          <section className="profileOverview">
            <div className="profileOverview__wrapper">
              <div>
                <Slider {...settings} className="profileOverview--slider">
                  <div>
                    <img
                      alt="黒光俊秀1の画像"
                      width="200"
                      height="200"
                      src="https://firebasestorage.googleapis.com/v0/b/kuro-site.appspot.com/o/thumbnails%2Fself-image.webp?alt=media&token=620a5e85-cd7d-4157-9820-e21b8cf9f613"
                      className="profileOverview--slider__img"
                    />
                  </div>
                  <div>
                    <img
                      alt="黒光俊秀2の画像"
                      width="200"
                      height="200"
                      src="https://firebasestorage.googleapis.com/v0/b/kuro-site.appspot.com/o/thumbnails%2Fself-image-2.webp?alt=media&token=bbad9cbd-7100-4921-b9d0-f8f469cbe510"
                      className="profileOverview--slider__img"
                    />
                  </div>
                  <div>
                    <img
                      alt="黒光俊秀3の画像"
                      width="200"
                      height="200"
                      src="https://firebasestorage.googleapis.com/v0/b/kuro-site.appspot.com/o/thumbnails%2Fself-image-3.webp?alt=media&token=6f94a57e-cf97-48b9-a48c-671e3e7578ca"
                      className="profileOverview--slider__img"
                    />
                  </div>
                </Slider>
              </div>
              <h1 className="profileOverview--name">黒光俊秀</h1>
              <p className="profileOverview--nameRuby">KUROMITSU TOSHIHIDE</p>
              <div className="profileOverview--sns">
                <a
                  href="https://github.com/kuroroblog"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="profileOverview--snsIcon"
                  aria-label="githubへのリンク"
                >
                  <svg viewBox="0 0 27 27" width="24" height="24">
                    <path
                      fill="currentColor"
                      d="M13.4 1.2C7 1 1.8 6 1.7 12.4v.5c0 5.1 3.2 9.8 8.2 11.5.6.1.7-.2.7-.6v-2.9s-3.3.6-4-1.5c0 0-.6-1.3-1.3-1.8 0 0-1.1-.7.1-.7.7.1 1.5.6 1.8 1.2.6 1.2 2.2 1.7 3.4 1h.1c.1-.6.4-1.2.7-1.6-2.7-.4-5.4-.6-5.4-5.2 0-1.1.5-2.1 1.2-2.8 0-1.1 0-2.2.3-3.2 1-.4 3.3 1.3 3.3 1.3 2-.6 4-.6 6 0 0 0 2.2-1.6 3.2-1.2.5 1 .5 2.2.1 3.2.7.7 1.2 1.8 1.2 2.8 0 4.5-2.8 5-5.5 5.2.6.6.9 1.3.7 2.2v4c0 .5.2.6.7.6 4.9-1.7 8.2-6.2 8-11.5.1-6.4-5.1-11.6-11.6-11.6-.1-.1-.2-.1-.2-.1z"
                    ></path>
                  </svg>
                </a>
                <a
                  href="https://twitter.com/KURORO59126227"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="profileOverview--snsIcon"
                  aria-label="twitterへのリンク"
                >
                  <svg viewBox="0 0 27 27" width="24" height="24">
                    <path
                      fill="currentColor"
                      d="M23.1 8.7v.7c0 8-6.4 14.5-14.4 14.6h-.2C5.7 24 3 23.2.6 21.7c.4 0 .8.1 1.2.1 2.3 0 4.6-.8 6.3-2.1C6 19.6 4 18.2 3.3 16c.3.1.7.1 1 .1.5 0 .9-.1 1.4-.2-2.4-.5-4.1-2.6-4.1-5.1v-.1c.7.4 1.5.6 2.3.7-1.5-1-2.2-2.5-2.2-4.3 0-.9.2-1.8.7-2.6C5 7.8 8.8 9.7 13 9.9c-.1-.4-.1-.8-.1-1.2 0-2.8 2.2-5.2 5.2-5.2 1.5 0 2.8.6 3.8 1.7C23 5 24 4.6 25 4.1c-.4 1.2-1.2 2.1-2.2 2.8 1-.1 2-.4 2.9-.8-.8 1-1.7 1.8-2.6 2.6z"
                    ></path>
                  </svg>
                </a>
                <a
                  href="https://www.wantedly.com/id/kurokuroro"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="profileOverview--snsIcon"
                  aria-label="wantedlyへのリンク"
                >
                  <svg viewBox="0 0 27 27" width="24" height="24">
                    <path
                      fill="currentColor"
                      d="M9.6 23.9c-3.6 0-6.5-3-6.5-6.6 0-1.7.7-3.4 1.9-4.6l2.3-2.3c.5-.4 1.2-.4 1.6.1.4.4.4 1 0 1.5l-2.3 2.3c-1.7 1.7-1.7 4.4 0 6.1s4.4 1.7 6.1 0l2.3-2.3c.5-.4 1.2-.4 1.6.1.4.4.4 1 0 1.5L14.3 22c-1.3 1.2-2.9 1.9-4.7 1.9zm1-6.4c-.6 0-1.1-.5-1.1-1.1 0-.3.1-.6.3-.8l5.8-5.8c.4-.4 1.1-.4 1.6 0 .4.4.4 1.1 0 1.6l-5.8 5.8c-.2.2-.5.3-.8.3zm8.3-.6c-.3 0-.6-.1-.8-.3-.4-.4-.4-1.1 0-1.6l2.3-2.3c1.7-1.7 1.7-4.4 0-6.1-1.7-1.7-4.4-1.7-6.1 0L12 8.9c-.5.4-1.2.4-1.6-.1-.4-.4-.4-1 0-1.5L12.7 5c2.6-2.6 6.7-2.6 9.2 0s2.6 6.7 0 9.2l-2.3 2.4c-.2.2-.5.3-.7.3z"
                    ></path>
                  </svg>
                </a>
              </div>
              <h2 className="profileOverview--about">ABOUT</h2>
              <p className="profileOverview--aboutRuby">私について</p>
              <hr />
              <dl className="profileOverview--desc">
                <dt>【生年月日】</dt>
                <dd>1994年12月7日</dd>
                <dt>【出身地】</dt>
                <dd>愛媛県</dd>
                <dt>【居住地】</dt>
                <dd>東京都</dd>
                <dt>【好きなこと】</dt>
                <dd>読書 / プログラミング / けん玉 / ナンプレ / ルービックキューブ / 夜景を見ること / 麻雀 / 筋トレ</dd>
              </dl>
              <h2 className="profileOverview--component">COMPONENT</h2>
              <p className="profileOverview--componentRuby">構成要素</p>
              <hr />
              <div className="profileOverview--componentList">
                <div className="profileOverview--componentListWrap">
                  <div className="profileOverview--component__common profileOverview--component__1">
                    どんな時も穏やか
                  </div>
                  <div className="profileOverview--component__common profileOverview--component__2">とっても真面目</div>
                  <div className="profileOverview--component__common profileOverview--component__3">熱意がある</div>
                  <div className="profileOverview--component__common profileOverview--component__4">問題解決志向</div>
                  <div className="profileOverview--component__common profileOverview--component__5">好奇心旺盛</div>
                  <div className="profileOverview--component__common profileOverview--component__6">行動力</div>
                  <div className="profileOverview--component__common profileOverview--component__7">何でも屋</div>
                  <div className="profileOverview--component__common profileOverview--component__8">論理的思考力</div>
                  <div className="profileOverview--component__common profileOverview--component__9">記憶力</div>
                  <div className="profileOverview--component__common profileOverview--component__10">努力家</div>
                </div>
              </div>
              <h2 className="profileOverview--programming">PROGRAMMING</h2>
              <p className="profileOverview--programmingRuby">プログラミング</p>
              <hr />
              <div className="profileOverview--programmingList">
                <div>
                  <p>
                    <strong>HTML</strong>
                  </p>
                  <div className="progress">
                    <div className="progress__color" style={{ width: '95%' }}></div>
                  </div>
                </div>
                <div>
                  <p>
                    <strong>CSS(SASS)</strong>
                  </p>
                  <div className="progress">
                    <div className="progress__color" style={{ width: '90%' }}></div>
                  </div>
                </div>
                <div>
                  <p>
                    <strong>Javascript, Typescript(Jquery, Vue, React)</strong>
                  </p>
                  <div className="progress">
                    <div className="progress__color" style={{ width: '80%' }}></div>
                  </div>
                </div>
                <div>
                  <p>
                    <strong>AWS(Lambda, CloudWatch, EC2, etc)</strong>
                  </p>
                  <div className="progress">
                    <div className="progress__color" style={{ width: '60%' }}></div>
                  </div>
                </div>
                <div>
                  <p>
                    <strong>GCP(Cloud Functions, GAE, BigQuery, etc)</strong>
                  </p>
                  <div className="progress">
                    <div className="progress__color" style={{ width: '80%' }}></div>
                  </div>
                </div>
                <div>
                  <p>
                    <strong>Python(Tkinter, Selenium, Scraping, etc)</strong>
                  </p>
                  <div className="progress">
                    <div className="progress__color" style={{ width: '90%' }}></div>
                  </div>
                </div>
                <div>
                  <p>
                    <strong>PHP(Laravel)</strong>
                  </p>
                  <div className="progress">
                    <div className="progress__color" style={{ width: '95%' }}></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="profileDesc">
            <div className="profileDescWrap">
              <div className="profileDesc--title">
                <span>
                  <i className="fas fa-briefcase profileDesc--icon" aria-hidden="true" />
                </span>
                <h3>JOB CAREER 職務経歴</h3>
              </div>
              <div className="profileDesc--detail">
                <span>
                  <i className="fas fa-circle" aria-hidden="true" />
                </span>
                <ul>
                  <li>
                    <p>
                      <strong>【会社名】</strong>
                    </p>
                    <p>株式会社Market Drive</p>
                  </li>
                  <li>
                    <p>
                      <strong>【在籍日】</strong>
                    </p>
                    <p>2016年10月 - 2017年7月</p>
                  </li>
                  <li>
                    <p>
                      <strong>【事業内容】</strong>
                    </p>
                    <p>完全審査制恋活マッチングアプリ「イヴイヴ」の運営</p>
                  </li>
                  <li>
                    <p>
                      <strong>【従業員数】</strong>
                    </p>
                    <p>5 - 10人</p>
                  </li>
                  <li>
                    <p>
                      <strong>【業務内容】</strong>
                    </p>
                    <p>
                      <strong>インターン生</strong>
                      として採用されました。<strong>若いうちに経験値を積んでおきたい</strong>と考え、
                      <strong>会社に泊まり込みで働き</strong>
                      ました。事務周りのお仕事からテレアポ、記事制作等へ従事しました。
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>【退職理由】</strong>
                    </p>
                    <p>
                      仕事を行う上で、人それぞれ、<strong>得意・不得意がある</strong>
                      ことを知りました。私は<strong>エンジニアに向いている</strong>
                      と感じ、会社が成長する可能性がある +
                      向上心を持つエンジニアが多い、別の会社で働くことを決意しました。
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>【関連リンク】</strong>
                    </p>
                    <ul>
                      <li>
                        <a href="https://eveeve.jp/" target="_blank" rel="noopener noreferrer">
                          完全審査制恋活マッチングアプリ「イヴイヴ」に関するサービスページ
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://prtimes.jp/main/html/rd/p/000000121.000022442.html"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          株式会社Market Driveから株式会社テックアイエスへ、商号変更のお知らせ
                        </a>
                      </li>
                      <li>
                        <a href="https://techis.jp/" target="_blank" rel="noopener noreferrer">
                          サポートに愛がある プログラミングスクール「TECH I.S.」に関するサービスページ
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/channel/UCMS3gJglLx5-uTgiD1JpTuA"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Youtube - EveEve - 恋愛サポートメディア
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

              <div className="profileDesc--detail">
                <span>
                  <i className="fas fa-circle" aria-hidden="true" />
                </span>
                <ul>
                  <li>
                    <p>
                      <strong>【会社名】</strong>
                    </p>
                    <p>株式会社終活ねっと</p>
                  </li>
                  <li>
                    <p>
                      <strong>【在籍日】</strong>
                    </p>
                    <p>2017年8月 - 2020年6月</p>
                  </li>
                  <li>
                    <p>
                      <strong>【事業内容】</strong>
                    </p>
                    <p>葬儀やお墓、相続など人生のエンディングにまつわる情報ポータルサイト「終活ねっと」の運営</p>
                  </li>
                  <li>
                    <p>
                      <strong>【従業員数】</strong>
                    </p>
                    <p>5 - 10人 ⏩ 150 - 200人</p>
                  </li>
                  <li>
                    <p>
                      <strong>【業務内容】</strong>
                    </p>
                    <p>
                      <strong>インターン生エンジニア</strong>
                      として採用されました。
                      <strong>最終的にはメディア事業部のエンジニア責任者</strong>
                      として、葬儀やお墓、相続など人生のエンディングにまつわる情報ポータルサイト「終活ねっと」のサービス運営を行いました。
                      <strong>
                        エンジニアリングする毎日が楽しくなり、気がついたら家を退去していて、
                        会社に泊まり込み(2年間)で働いて
                      </strong>
                      いました。
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>【退職理由】</strong>
                    </p>
                    <p>
                      フリーで働いてみたいと感じるようになった。サービスを立ち上げるところから、関われる会社で働いてみたいと考えるようになった。
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>【実績】</strong>
                    </p>
                    <ul>
                      <li>
                        <p>
                          入社4ヶ月で<strong>初社員</strong>になりました。
                        </p>
                      </li>
                      <li>
                        <p>
                          エンジニアリングする毎日が楽しくなり、気がついたら家を退去していて、
                          <strong>会社に泊まり込み(2年間)で働いて</strong>いました。
                        </p>
                      </li>
                      <li>
                        <p>
                          インターン生エンジニア ⏩ <strong>メディア事業部のエンジニア責任者</strong>となりました。
                        </p>
                      </li>
                      <li>
                        <p>
                          葬儀やお墓、相続など人生のエンディングにまつわる情報ポータルサイト「終活ねっと」が、
                          <strong>月間1000万PVを達成</strong>しました。
                        </p>
                      </li>
                      <li>
                        <p>
                          2018年10月12日に、<strong>M&AによりDMMグループ入り</strong>を果たしました。
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>
                      <strong>【関連リンク】</strong>
                    </p>
                    <ul>
                      <li>
                        <a
                          href="https://webtan.impress.co.jp/e/2020/04/16/35648"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          立上げ2年半で月間1,000万PVを記録！ 急成長する「終活ねっと」のコンテンツ重視戦略とは？
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://jp.techcrunch.com/2018/11/12/dmm-syukatsunet/?guccounter=1&guce_referrer=aHR0cHM6Ly9rdXJvcm8uYmxvZy8&guce_referrer_sig=AQAAALO0DmaOpYol_ND3-TLl0trO7DwcAxQfYjXWsLyrrEbFldLQ3uAHAfXSU9upRGqpMsGMxhPDluoBh01bqErGF3Wrm5dbTuz88WIm-hxCU7WyINW6_hx18IN9uBAQG1NlCIzRR90a9O8J2b7ue7r-TjDMWHFfpj6yqecJbl65Uf9F"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          DMM、葬儀など“終活”情報ポータル提供する終活ねっとを買収ーー取得額は10億円程度か
                        </a>
                      </li>
                      <li>
                        <a href="https://www.businessinsider.jp/post-179358" target="_blank" rel="noopener noreferrer">
                          現役東大生が｢終活ねっと｣でスピード起業できた理由 —— DMMが買収、変わるベンチャー出資
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

              <div className="profileDesc--detail">
                <span>
                  <i className="fas fa-circle" aria-hidden="true" />
                </span>
                <ul>
                  <li>
                    <p>
                      <strong>【会社名】</strong>
                    </p>
                    <p>株式会社Light</p>
                  </li>
                  <li>
                    <p>
                      <strong>【在籍日】</strong>
                    </p>
                    <p>2020年5月 - 2021年4月</p>
                  </li>
                  <li>
                    <p>
                      <strong>【事業内容】</strong>
                    </p>
                    <p>ビデオデート特化のマッチングサービス 「palmu（パルム）」の運営</p>
                  </li>
                  <li>
                    <p>
                      <strong>【従業員数】</strong>
                    </p>
                    <p>5 - 10人</p>
                  </li>
                  <li>
                    <p>
                      <strong>【業務内容】</strong>
                    </p>
                    <p>
                      <strong>フリーランスエンジニア</strong>
                      として採用されました。バックエンドエンジニアとして活動し、アプリのための
                      <strong>APIサーバー構築、インフラ全般を一から立ち上げ</strong>ました。
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>【退職理由】</strong>
                    </p>
                    <p>
                      結婚や子供を育てる可能性を鑑みると、会社へ勤めるよりも起業して事業を作る経験を積んでおくべきだと考えるため。
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>【関連リンク】</strong>
                    </p>
                    <ul>
                      <li>
                        <a href="https://palmuapp.com/" target="_blank" rel="noopener noreferrer">
                          ビデオデート特化のマッチングサービス palmu（パルム）に関するサービスページ
                        </a>
                      </li>
                      <li>
                        <a href="https://light-inc.com/" target="_blank" rel="noopener noreferrer">
                          株式会社Lightホームページ
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

              <div className="profileDesc--title profileDesc--titleForActivity">
                <span>
                  <i className="fas fa-user-ninja profileDesc--icon profileDesc--iconForActivity" aria-hidden="true" />
                </span>
                <h3>CURRENT ACTIVITY 現在の活動</h3>
              </div>

              <div className="profileDesc--detail profileDesc--detailForActivity">
                <ul>
                  <li>
                    <p>
                      2022年2月15日に
                      <strong>
                        <a
                          href="https://www.houjin-bangou.nta.go.jp/henkorireki-johoto.html?selHouzinNo=2010401165604"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          株式会社BlackLightを設立
                        </a>
                      </strong>
                      し、4つのことを主軸に活動している。
                    </p>
                  </li>

                  <br />

                  <li>
                    <p>
                      <strong>【タイトル】</strong>
                    </p>
                    <p>KURORO BLOGの運営。</p>
                  </li>
                  <li>
                    <p>
                      <strong>【事業内容】</strong>
                    </p>
                    <p>プログラミングに関心を持ってもらうための情報共有サイト「KURORO BLOG」の運営。</p>
                  </li>
                  <li>
                    <p>
                      <strong>【業務内容】</strong>
                    </p>
                    <p>
                      KURORO BLOGの運営 & 執筆を行っております。 KURORO
                      BLOGは、「モノづくりから始まるエンジニア」をコンセプトに、プログラミングに関心を持ってもらうための情報共有サイトです。
                    </p>
                  </li>

                  <br />

                  <li>
                    <p>
                      <strong>【タイトル】</strong>
                    </p>
                    <p>株式会社Medicalforceのエンジニアとして活動。</p>
                  </li>
                  <li>
                    <p>
                      <strong>【事業内容】</strong>
                    </p>
                    <p>
                      美容クリニック・自由診療クリニックの業務・経営の全てを管理する、Saas型プラットフォームの運営。
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>【業務内容】</strong>
                    </p>
                    <p>
                      上記Saas型プラットフォームのバックエンドエンジニアとして従事。設計から実装、テストまで行っております。
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>【関連リンク】</strong>
                    </p>
                    <ul>
                      <li>
                        <a href="https://service.medical-force.com/" target="_blank" rel="noopener noreferrer">
                          株式会社Medicalforce - サービスページ
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.wantedly.com/companies/medicalforce"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          株式会社Medicalforce - 採用ページ
                        </a>
                      </li>
                    </ul>
                  </li>

                  <br />

                  <li>
                    <p>
                      <strong>【タイトル】</strong>
                    </p>
                    <p>株式会社レジャーの技術顧問として活動。</p>
                  </li>
                  <li>
                    <p>
                      <strong>【事業内容】</strong>
                    </p>
                    <p>
                      ゲーム・eSports・NFTゲームなどのメディア
                      <a href="https://espo-game.jp/" target="_blank" rel="noopener noreferrer">
                        Ludus(ルーダス)
                      </a>
                      の運営。
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>【業務内容】</strong>
                    </p>
                    <p>主に業務を解決に導く設計、より良いコードの書き方等を、レクチャーしております。</p>
                  </li>
                  <li>
                    <p>
                      <strong>【関連リンク】</strong>
                    </p>
                    <ul>
                      <li>
                        <a href="https://espo-game.jp/" target="_blank" rel="noopener noreferrer">
                          ゲーム・eSports・NFTゲームなどのメディア - Ludus(ルーダス)
                        </a>
                      </li>
                      <li>
                        <a href="https://leisure-inc.co.jp/" target="_blank" rel="noopener noreferrer">
                          株式会社レジャー - 会社概要
                        </a>
                      </li>
                    </ul>
                  </li>

                  <br />

                  <li>
                    <p>
                      <strong>【タイトル】</strong>
                    </p>
                    <p>株式会社Ownedの技術顧問として活動。</p>
                  </li>
                  <li>
                    <p>
                      <strong>【事業内容】</strong>
                    </p>
                    <p>受託開発。 オンライン診療支援。</p>
                  </li>
                  <li>
                    <p>
                      <strong>【業務内容】</strong>
                    </p>
                    <p>
                      主に各種サービスのコードレビュー、サイト・機能設計、エンジニア採用支援、エンジニア組織支援を行っております。
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>【関連リンク】</strong>
                    </p>
                    <ul>
                      <li>
                        <a href="https://owned.co.jp/recruits/" target="_blank" rel="noopener noreferrer">
                          株式会社Owned - 採用ページ1
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.wantedly.com/companies/company_9585149"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          株式会社Owned - 採用ページ2
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

              <div className="profileDesc--title profileDesc--titleForComment">
                <span>
                  <i className="fas fa-user-edit profileDesc--icon profileDesc--iconForComment" aria-hidden="true" />
                </span>
                <h3>OPERATOR COMMENT 運営者コメント</h3>
              </div>
              <div className="profileDesc--detail profileDesc--detailForComment">
                <ul>
                  <li>
                    <p>
                      <strong>【ブログを通して提供できる価値】</strong>
                    </p>
                    <p>
                      実際にプログラムを作成し、<strong>手元環境で動作確認している</strong>
                      ため、<strong>一次情報をもとに記事作成</strong>している。
                    </p>
                    <p>
                      現役のエンジニアとして<strong>プログラミング経験を積んでいる</strong>ため、
                      <strong>躓きやすいポイントやわかりにくい用語などを考慮</strong>した上で、ブログ運営している。
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>【KURORO BLOGを始めたきっかけ】</strong>
                    </p>
                    <p>
                      <strong>「より多くの方へ価値提供できることは何か？」</strong>を考えたときに、(メディア運営 +
                      エンジニアリング)経験を最大限に生かせる、ブログ運営が最適だと思い、今に至ります。
                    </p>
                    <p>
                      また一次情報の元、<strong>正しくわかりやすい情報が、整理されていない</strong>
                      と感じたため、KURORO BLOGを立ち上げました。
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>【特に読んで欲しいブログ記事5選】</strong>
                    </p>
                    <ul>
                      <li>
                        <a href="/python/0Sd4HERaGbzIpSsyEVoz/">
                          【Pythonコード付】LambdaとSeleniumでスクリーンショット自動化
                        </a>
                      </li>
                      <li>
                        <a href="/python/ngQRGzqfuyK8WaCwkGid/">
                          Tkinterで使われるafterとは?活用方法から注意点まで徹底解説!?
                        </a>
                      </li>
                      <li>
                        <a href="/python/kEE4RwgYN45V0lM92SDM/">
                          Tkinterで使われるtoplevelとは?Windowの説明を交えて徹底解説
                        </a>
                      </li>
                      <li>
                        <a href="/python/zTeIS7KyJGQv87n1kue5/">
                          【もう迷わない!? mac編】Pyenv環境からTkinterをインストール
                        </a>
                      </li>
                      <li>
                        <a href="/python/JoaowDiUdLAOj3cSBxiX/">
                          【徹底解説!?】Tkinterで使われるgridの活用方法を完全マスター!?
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>

        <Breadcrumb
          breadcrumb={[
            {
              link: '/',
              text: 'KURORO BLOG',
            },
            {
              link: '/profile/',
              text: '黒光俊秀のプロフィール詳細',
            },
          ]}
        />

        <Footer />
      </div>
    </div>
  )
}
